.container {
  background: #f5f5f5;
  padding: 35px 25px;
  position: relative;

  &:nth-child(even) {
    background: #fff;
  }
}

.activeContainer {
  background: #eefaff;

  &:nth-child(even) {
    background: #eefaff;
  }
}

.arabic {
  direction: rtl;
  font-size: 46px;
  margin-bottom: 10px;
  white-space: pre-line;
  color: #000;
  overflow-wrap: break-word;
  line-height: 1.5;
  word-break: break-all;
  text-align: right;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 580px) {
    line-height: 130%;
  }
}

.word {
  padding: 0 2px;
  cursor: pointer;

  &:hover {
    color: #03a9f4;
  }

  @media (max-width: 768px) {
    &:hover {
      color: #000;
    }
  }
}

.activeWord {
  color: #03a9f4 !important;
}

.translation {
  font-size: 15px;
  line-height: 24px;
  color: #555555;
}

.number {
  position: absolute;
  left: -30px;
  top: 15px;
  font-weight: 700;
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media (max-width: 960px) {
    left: -16px;
  }
}

.playAyat {
  position: absolute;
  left: -24px;
  top: 55px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #03a9f4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  user-select: none;

  @media (max-width: 960px) {
    left: -11px;
  }

  &:hover {
    background: #1db9ff;
  }

  & svg {
    width: 12px;
    height: 12px;
    fill: #fff;
  }
}
