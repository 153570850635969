* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: sura_names;
  src: url('/assets/fonts/sura_names.eot');
  src: url('/assets/fonts/sura_names.eot#iefix') format('embedded-opentype'),
    url('/assets/fonts/sura_names.ttf') format('truetype'),
    url('/assets/fonts/sura_names.woff') format('woff'),
    url('/assets/fonts/sura_names.svg#surah_names') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: me_quran;
  src: url('/assets/fonts/me_quran.ttf') format('truetype');
}

@for $i from 1 through 604 {
  @font-face {
    font-family: p#{$i};
    src: url('/assets/fonts/ttf/p#{$i}.ttf') format('truetype');
  }
  .p#{$i} {
    font-family: p#{$i};
  }
}

@font-face {
  font-family: 'bismillah';
  src: url('/assets/fonts/bismillah/bismillah.eot?yg3f94');
  src: url('/assets/fonts/bismillah/bismillah.eot?yg3f94#iefix') format('embedded-opentype'),
    url('/assets/fonts/bismillah/bismillah.ttf?yg3f94') format('truetype'),
    url('/assets/fonts/bismillah/bismillah.woff?yg3f94') format('woff'),
    url('/assets/fonts/bismillah/bismillah.svg?yg3f94#bismillah') format('svg');
  font-weight: normal;
  font-style: normal;
}

#bismillah {
  font-family: 'bismillah';
  font-size: 60px;
  color: #000;
  padding: 25px 0;

  @media (max-width: 580px) {
    font-size: 36px;
  }
}

html,
body {
  height: 100%;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Helvetica, 'Roboto', 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif;
}

#root {
  height: 100%;
}

.loadmore {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 800px;
  box-sizing: border-box;
  z-index: -1;
}

blockquote {
  margin-bottom: 20px;
  line-height: 1.7;
  padding: 20px 20px 20px 40px;
  border-left: 5px solid #ddd;
  background: #f9f9f9;

  & cite {
    display: block;
    text-align: right;
    font-size: 11px;
    margin-top: 5px;
  }
}

.wrap {
  width: 1170px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 1170px) {
    width: auto;
    margin: 0;
    padding: 0 20px;
  }
}

.alert {
  position: relative;
  padding: 15px 15px 15px 64px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5715;
  border-radius: 2px;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;

  & a {
    text-decoration: none;
    color: #009eff;

    &:hover {
      text-decoration: underline;
    }
  }

  &__icon {
    position: absolute;
    top: 16px;
    left: 24px;
    font-size: 24px;
    color: #1890ff;
  }

  &__title {
    display: block;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: normal;
  }

  &__text {
    font-size: 14px;
    line-height: 22px;
  }
}

.title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 25px;

  @media (max-width: 580px) {
    font-size: 24px;
  }
}

.end {
  color: #000;
  cursor: default;

  &:hover {
    color: #000;
  }
}

.page {
  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px;
  }

  & p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & a {
    color: #03a9f4;

    &:hover {
      text-decoration: none;
    }
  }
}

[class^='icon-chapter'],
[class*=' icon-chapter'] {
  font-family: sura_names !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chapter1:before {
  content: '\E904';
}

.icon-chapter2:before {
  content: '\E905';
}

.icon-chapter3:before {
  content: '\E906';
}

.icon-chapter4:before {
  content: '\E907';
}

.icon-chapter5:before {
  content: '\E908';
}

.icon-chapter6:before {
  content: '\E90b';
}

.icon-chapter7:before {
  content: '\E90c';
}

.icon-chapter8:before {
  content: '\E90d';
}

.icon-chapter9:before {
  content: '\E90e';
}

.icon-chapter10:before {
  content: '\E90f';
}

.icon-chapter11:before {
  content: '\E910';
}

.icon-chapter12:before {
  content: '\E911';
}

.icon-chapter13:before {
  content: '\E912';
}

.icon-chapter14:before {
  content: '\E913';
}

.icon-chapter15:before {
  content: '\E914';
}

.icon-chapter16:before {
  content: '\E915';
}

.icon-chapter17:before {
  content: '\E916';
}

.icon-chapter18:before {
  content: '\E917';
}

.icon-chapter19:before {
  content: '\E918';
}

.icon-chapter20:before {
  content: '\E919';
}

.icon-chapter21:before {
  content: '\E91a';
}

.icon-chapter22:before {
  content: '\E91b';
}

.icon-chapter23:before {
  content: '\E91c';
}

.icon-chapter24:before {
  content: '\E91d';
}

.icon-chapter25:before {
  content: '\E91e';
}

.icon-chapter26:before {
  content: '\E91f';
}

.icon-chapter27:before {
  content: '\E920';
}

.icon-chapter28:before {
  content: '\E921';
}

.icon-chapter29:before {
  content: '\E922';
}

.icon-chapter30:before {
  content: '\E923';
}

.icon-chapter31:before {
  content: '\E924';
}

.icon-chapter32:before {
  content: '\E925';
}

.icon-chapter33:before {
  content: '\E926';
}

.icon-chapter34:before {
  content: '\E92e';
}

.icon-chapter35:before {
  content: '\E92f';
}

.icon-chapter36:before {
  content: '\E930';
}

.icon-chapter37:before {
  content: '\E931';
}

.icon-chapter38:before {
  content: '\E909';
}

.icon-chapter39:before {
  content: '\E90a';
}

.icon-chapter40:before {
  content: '\E927';
}

.icon-chapter41:before {
  content: '\E928';
}

.icon-chapter42:before {
  content: '\E929';
}

.icon-chapter43:before {
  content: '\E92a';
}

.icon-chapter44:before {
  content: '\E92b';
}

.icon-chapter45:before {
  content: '\E92c';
}

.icon-chapter46:before {
  content: '\E92d';
}

.icon-chapter47:before {
  content: '\E932';
}

.icon-chapter48:before {
  content: '\E902';
}

.icon-chapter49:before {
  content: '\E933';
}

.icon-chapter50:before {
  content: '\E934';
}

.icon-chapter51:before {
  content: '\E935';
}

.icon-chapter52:before {
  content: '\E936';
}

.icon-chapter53:before {
  content: '\E937';
}

.icon-chapter54:before {
  content: '\E938';
}

.icon-chapter55:before {
  content: '\E939';
}

.icon-chapter56:before {
  content: '\E93a';
}

.icon-chapter57:before {
  content: '\E93b';
}

.icon-chapter58:before {
  content: '\E93c';
}

.icon-chapter59:before {
  content: '\E900';
}

.icon-chapter60:before {
  content: '\E901';
}

.icon-chapter61:before {
  content: '\E941';
}

.icon-chapter62:before {
  content: '\E942';
}

.icon-chapter63:before {
  content: '\E943';
}

.icon-chapter64:before {
  content: '\E944';
}

.icon-chapter65:before {
  content: '\E945';
}

.icon-chapter66:before {
  content: '\E946';
}

.icon-chapter67:before {
  content: '\E947';
}

.icon-chapter68:before {
  content: '\E948';
}

.icon-chapter69:before {
  content: '\E949';
}

.icon-chapter70:before {
  content: '\E94a';
}

.icon-chapter71:before {
  content: '\E94b';
}

.icon-chapter72:before {
  content: '\E94c';
}

.icon-chapter73:before {
  content: '\E94d';
}

.icon-chapter74:before {
  content: '\E94e';
}

.icon-chapter75:before {
  content: '\E94f';
}

.icon-chapter76:before {
  content: '\E950';
}

.icon-chapter77:before {
  content: '\E951';
}

.icon-chapter78:before {
  content: '\E952';
}

.icon-chapter79:before {
  content: '\E93d';
}

.icon-chapter80:before {
  content: '\E93e';
}

.icon-chapter81:before {
  content: '\E93f';
}

.icon-chapter82:before {
  content: '\E940';
}

.icon-chapter83:before {
  content: '\E953';
}

.icon-chapter84:before {
  content: '\E954';
}

.icon-chapter85:before {
  content: '\E955';
}

.icon-chapter86:before {
  content: '\E956';
}

.icon-chapter87:before {
  content: '\E957';
}

.icon-chapter88:before {
  content: '\E958';
}

.icon-chapter89:before {
  content: '\E959';
}

.icon-chapter90:before {
  content: '\E95a';
}

.icon-chapter91:before {
  content: '\E95b';
}

.icon-chapter92:before {
  content: '\E95c';
}

.icon-chapter93:before {
  content: '\E95d';
}

.icon-chapter94:before {
  content: '\E95e';
}

.icon-chapter95:before {
  content: '\E95f';
}

.icon-chapter96:before {
  content: '\E960';
}

.icon-chapter97:before {
  content: '\E961';
}

.icon-chapter98:before {
  content: '\E962';
}

.icon-chapter99:before {
  content: '\E963';
}

.icon-chapter100:before {
  content: '\E964';
}

.icon-chapter101:before {
  content: '\E965';
}

.icon-chapter102:before {
  content: '\E966';
}

.icon-chapter103:before {
  content: '\E967';
}

.icon-chapter104:before {
  content: '\E968';
}

.icon-chapter105:before {
  content: '\E969';
}

.icon-chapter106:before {
  content: '\E96a';
}

.icon-chapter107:before {
  content: '\E96b';
}

.icon-chapter108:before {
  content: '\E96c';
}

.icon-chapter109:before {
  content: '\E96d';
}

.icon-chapter110:before {
  content: '\E96e';
}

.icon-chapter111:before {
  content: '\E96f';
}

.icon-chapter112:before {
  content: '\E970';
}

.icon-chapter113:before {
  content: '\E971';
}

.icon-chapter114:before {
  content: '\E972';
}
