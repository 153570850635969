.footer {
  padding: 20px 0;
  font-size: 12px;
  color: #888;

  & a {
    color: #666;

    &:hover {
      text-decoration: none;
    }
  }
}

.wrap {
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.nav {
  display: flex;
  flex: 1;
  justify-content: flex-end;

  & a {
    display: inline-block;
    margin-left: 15px;
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    margin-top: 2px;

    & a {
      margin: 0 15px 0 0;
      color: #aaa;
    }
  }
}
