.parent {
  text-decoration: none;
  color: #222222;
  display: inline-block;
}

.logo {
  font-size: 24px;
  font-weight: 700;
}

.ayat {
  font-size: 12px;
  color: #ccc;
}

.mushaf {
  width: 70px;
  height: 70px;
}
