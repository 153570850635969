.container {
  flex-basis: 16.6666667%;
  max-width: 16.6666667%;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #222;

  &:nth-child(odd) {
    & .box {
      background: #fff;
    }
  }

  &:hover {
    & .box {
      background: #fffbd9;
    }
  }

  @media (max-width: 1600px) {
    flex-basis: 25%;
    max-width: 25%;
  }

  @media (max-width: 960px) {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }

  @media (max-width: 768px) {
    flex-basis: 50%;
    max-width: 50%;
  }

  @media (max-width: 571px) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.box {
  background: #f5f5f5;
  padding: 20px;
  display: flex;
  width: 100%;
  box-shadow: 0 0 0 1px #eee;
  transition: all 0.3s;
}

.number {
  font-size: 24px;
  flex: 0 0 50px;
  margin-right: 10px;

  @media (max-width: 1170px) {
    margin-right: 0;
  }
}

.info {
  flex: 1;
}

.name {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 2px;
}

.translit {
  font-size: 12px;
  color: #777777;
}
